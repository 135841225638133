<template>
  <div class="body">
    <div class="container">
      <branches-side-nav />
      <router-view  :key="key" />
    </div>
  </div>
</template>

<script>
import BranchesSideNav from '@/components/Branches/SideNav.vue'
export default {
  name: 'App',
  components: {
    BranchesSideNav,
  },
  computed:{
    key(){
      let id = this.$route.params.id ? this.$route.params.id : ""
      let index = this.$route.meta.index ? this.$route.meta.index: ""

      return id + index
    }
  },
  mounted(){
    console.log(this.key)
  }
}
</script>

<style>
</style>

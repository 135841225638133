<template>
    <div class="side-card">
		<h1 class="side-card--heading">Sections</h1>
		<div class="side-card--link-container" v-if="userPermissions ? userPermissions.can_view_products : true">
			<router-link @click="setMobileSideNav" class="side-card__link" :class="{'side-card__link--product-active':isProducts}" :to="{name:'Product-Category',params:{id:branchId}}">
				<img class="side-card__link-image" src="@/assets/images/Sections/products.svg">
				<h1 class="side-card__link-name">Products</h1>
			</router-link>
		</div>

		<template v-for="link in links" :key="link.name" >
			<div class="side-card--link-container" v-if="userPermissions ? userPermissions[link.view] : true">
				<router-link @click="setMobileSideNav" class="side-card__link" :to="{ name:link.name ,params:{id:branchId} }" >
					<img class="side-card__link-image" :src="require(`@/assets/images/Sections/${link.name}.svg`)">
					<h1 class="side-card__link-name">{{ link.name }}</h1>
				</router-link>
			</div>
		</template>
		
	</div>
</template>

<script>
export default {
	data(){
		return{
			links: 
			[
				{name:'consignments',view:'can_view_consignments'},
				{name:'orders',view:'can_view_orders'},
				{name:'sales',view:'can_view_sales'},
				{name:'credits',view:'can_view_account_tabs'},
				{name:'expenses',view:'can_view_expenses'},
				{name:'staff',view:'can_view_staff'},
				{name:'contacts',view:'can_view_contacts'},
				{name:'analysis',view:'can_view_analyses'},
				{name:'activity',view:'can_view_activity_log'},
			]
		}
	},
	computed:{
		//delete after restructuring routes
		mobileSideNav(){
			return this.$store.state.mobileSideNav
		},
		isProducts(){
			return (this.$route.params.section === 'products')
		},
		branchId(){
			return this.$store.state.branchId
		},
		userPermissions(){
			return this.$store.state.user.user_role 
		}

	},
	methods:{
		setMobileSideNav(){
			this.$store.commit({type:'setMobileSideNav',mobileSideNav:true})
		}
	},

	
}
</script>

<template>
 <div class="side-nav" :class="{'side-nav__mobile-hide':mobileSideNav}">
  <div class="side-nav__overlay" @click="setMobileSideNav">
  </div>
  <div class="side-nav__container">
    <branch-sections-card v-if="sectionsNavSections" />
    <options-card v-if="sideNavOptions"/>

  </div>
   
  </div>
</template>

<script>
import OptionsCard from '@/components/Branches/SideCardOptions.vue'
import BranchSectionsCard from '@/components/Branches/SideCardBranchSections.vue'


export default {
  name: 'BranchSideNav',
  components:{
    OptionsCard,
    BranchSectionsCard,
  },
  computed:{
    mobileSideNav(){
      return this.$store.state.mobileSideNav
    },
    sectionsNavSections(){
      return this.$route.meta['OPTIONS'].sideSelector
    },
    sideNavOptions(){
      return this.$route.meta.sideOptions
    },
    sideNavDateSelector(){
      return this.$route.meta['OPTIONS'].sideDateSelector
    }

  },
  methods:{
    setMobileSideNav(){
      this.$store.commit({type:'setMobileSideNav',mobileSideNav:true})
    }
  },
  
}
</script>

<template>
	<div class="side-card side-card--options">
		<h1 class="side-card--heading">Sort</h1>
		<div class="side-card--section">
			<side-card-select name="type" :options="['ascending','descending']" />
		</div>
		<div class="side-card--section">
			<side-card-select name="value" :options="['Name','Revenue']" />
		</div>
	</div>
</template>
<script>
import SideCardSelect from '@/components/SideCardSelect.vue'

export default {
  components: { SideCardSelect },
}
</script>
